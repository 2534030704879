import { colorArrayToRgb, Color } from '@methanesat/colors';
import { getFilledTargetColor, TARGET_GREY_DATA } from '../../consts';
import { StacFeature, Platforms } from '../../types';

export const captureFeaturesToDateBlocks = (feature: StacFeature, platform: Platforms) => {
    const captureDate = new Date(feature.properties?.datetime as string);
    const totalKgHr = feature.properties?.net_total;
    const targetColor = totalKgHr
        ? colorArrayToRgb(getFilledTargetColor(totalKgHr, platform) as Color)
        : TARGET_GREY_DATA;
    const coordinates = feature.geometry.type === 'Polygon' ? feature.geometry.coordinates[0] : null;
    return {
        collection: feature.collection,
        color: targetColor,
        coordinates: coordinates!,
        date: captureDate,
        id: feature.id,
        platform: feature.properties?.instrument,
        totalKgHr
    };
};
