/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 25
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/methanesat/Data%20Portal/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development' | 'staging' | 'sandbox';

export const ApiKey: Record<Environment, string> = {
  production: '1b010b0a162fd26d546e146077da87e1',
  development: 'd2cda2ea7a116624dd7b4e2f06617ca1',
  staging: '2be7627cf55ebaa33b9e1f2e2f18cac7',
  sandbox: '3600ed92a36606bda9fda4fae22da98a'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '25',
    branch: 'main',
    source: 'web',
    versionId: '5749fd1d-8b31-46ee-b1fb-8190bf0d0f5b'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface ChooseGlobalDateProperties {
  label: string;
}

export interface ClickTargetDrawerObservationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  emissionRate: number;
  /**
   * STAC Item ID
   */
  itemId: string;
  label: string;
  platform: string;
}

export interface ClickZoomProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | in|out |
   */
  direction: string;
  /**
   * An integer value. It is possible that the actual zoom in the web application is a decimal. It is an integer to make analytics a bit easier.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  zoom: number;
}

export interface FilterAreaEmissionsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  invisibleLayers: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  visibleLayers: string[];
}

export interface FilterOperatorsProperties {
  /**
   * Object of operator_id as a string
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  operators: string[];
}

export interface FilterPlumeEmissionsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  invisibleLayers: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  visibleLayers: string[];
}

export interface OgiFilterByCategoryProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  categories: string[];
}

export interface OgiFilterByOperatorProperties {
  /**
   * Object of operator_id as a string
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  operators: string[];
}

export interface OpenAreaEmissionDetailsProperties {
  /**
   * Longitude and latitude coordinates
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  coordinates?: number[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  emissionRate: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  latitude?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  longitude?: number;
}

export interface OpenBasinDetailsProperties {
  name: string;
}

export interface OpenLegendProperties {
  /**
   * Where the legend was opened from.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | mini legend|speed dial |
   */
  source: string;
}

export interface OpenMethaneLayerDrawerFromUrlProperties {
  collectionId: string;
  /**
   * STAC Item ID
   */
  itemId: string;
  layerId: string;
}

export interface OpenMultiFeatureDetailsProperties {
  featureId: string;
  featureType: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  latitude?: number;
  layerId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  longitude?: number;
}

export interface OpenNotificationsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  count: number;
  severity?: string;
}

export interface OpenPipelineDetailsProperties {
  /**
   * Longitude and latitude coordinates
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  coordinates?: number[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  latitude?: number;
  /**
   * Length of the pipeline
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  length?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  longitude?: number;
  operatorName?: string;
}

export interface OpenPlumeEmissionDetailsProperties {
  /**
   * Longitude and latitude coordinates
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  coordinates?: number[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  emissionRate: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  latitude?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  longitude?: number;
}

export interface OpenPointInfrastructureDetailsProperties {
  accordion?: boolean;
  /**
   * Longitude and latitude coordinates
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  coordinates?: number[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  latitude?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  longitude?: number;
  operatorName?: string;
  type: string;
}

export interface OpenTargetDetailsProperties {
  name: string;
}

export interface SelectPlatformProperties {
  platform: string;
}

export interface ShowOgiNearPlumesProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  emissionRate: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  latitude?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  longitude?: number;
}

export interface ToggleAreaEmissionDataProperties {
  visible: boolean;
}

export interface ToggleAreaEmissionsProductProperties {
  /**
   * Where the event was triggered
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | mini legend|speed dial |
   */
  source: string;
  value: string;
}

export interface ToggleOilAndGasDataProperties {
  visible: boolean;
}

export interface TogglePlumeEmissionDataProperties {
  visible: boolean;
}

export interface ToggleSpeedDialProperties {
  open: boolean;
}

export class ChooseGlobalDate implements BaseEvent {
  event_type = 'choose global date';

  constructor(
    public event_properties: ChooseGlobalDateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickIntroAreaEmissions implements BaseEvent {
  event_type = 'click intro area emissions';
}

export class ClickIntroFilterOgi implements BaseEvent {
  event_type = 'click intro filter ogi';
}

export class ClickIntroOgi implements BaseEvent {
  event_type = 'click intro ogi';
}

export class ClickIntroPointSources implements BaseEvent {
  event_type = 'click intro point sources';
}

export class ClickIntroRegionalEmissions implements BaseEvent {
  event_type = 'click intro regional emissions';
}

export class ClickIntroZoom implements BaseEvent {
  event_type = 'click intro zoom';
}

export class ClickTargetDrawerObservation implements BaseEvent {
  event_type = 'click target drawer observation';

  constructor(
    public event_properties: ClickTargetDrawerObservationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickZoom implements BaseEvent {
  event_type = 'click zoom';

  constructor(
    public event_properties: ClickZoomProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterAreaEmissions implements BaseEvent {
  event_type = 'filter area emissions';

  constructor(
    public event_properties: FilterAreaEmissionsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterOperators implements BaseEvent {
  event_type = 'filter operators';

  constructor(
    public event_properties: FilterOperatorsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterPlumeEmissions implements BaseEvent {
  event_type = 'filter plume emissions';

  constructor(
    public event_properties: FilterPlumeEmissionsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OgiFilterByCategory implements BaseEvent {
  event_type = 'ogi filter by category';

  constructor(
    public event_properties: OgiFilterByCategoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OgiFilterByOperator implements BaseEvent {
  event_type = 'ogi filter by operator';

  constructor(
    public event_properties: OgiFilterByOperatorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenAreaEmissionDetails implements BaseEvent {
  event_type = 'open area emission details';

  constructor(
    public event_properties: OpenAreaEmissionDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenBasinDetails implements BaseEvent {
  event_type = 'open basin details';

  constructor(
    public event_properties: OpenBasinDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenDownload implements BaseEvent {
  event_type = 'open download';
}

export class OpenLegend implements BaseEvent {
  event_type = 'open legend';

  constructor(
    public event_properties: OpenLegendProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenMethaneLayerDrawerFromUrl implements BaseEvent {
  event_type = 'open methane layer drawer from url';

  constructor(
    public event_properties: OpenMethaneLayerDrawerFromUrlProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenMultiFeatureDetails implements BaseEvent {
  event_type = 'open multi feature details';

  constructor(
    public event_properties: OpenMultiFeatureDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenNoData implements BaseEvent {
  event_type = 'open no data';
}

export class OpenNotifications implements BaseEvent {
  event_type = 'open notifications';

  constructor(
    public event_properties: OpenNotificationsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenPipelineDetails implements BaseEvent {
  event_type = 'open pipeline details';

  constructor(
    public event_properties: OpenPipelineDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenPlumeEmissionDetails implements BaseEvent {
  event_type = 'open plume emission details';

  constructor(
    public event_properties: OpenPlumeEmissionDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenPointInfrastructureDetails implements BaseEvent {
  event_type = 'open point infrastructure details';

  constructor(
    public event_properties: OpenPointInfrastructureDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenSettings implements BaseEvent {
  event_type = 'open settings';
}

export class OpenTargetDetails implements BaseEvent {
  event_type = 'open target details';

  constructor(
    public event_properties: OpenTargetDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectPlatform implements BaseEvent {
  event_type = 'select platform';

  constructor(
    public event_properties: SelectPlatformProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShowOgiNearPlumes implements BaseEvent {
  event_type = 'show ogi near plumes';

  constructor(
    public event_properties: ShowOgiNearPlumesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleAreaEmissionData implements BaseEvent {
  event_type = 'toggle area emission data';

  constructor(
    public event_properties: ToggleAreaEmissionDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleAreaEmissionsProduct implements BaseEvent {
  event_type = 'toggle area emissions product';

  constructor(
    public event_properties: ToggleAreaEmissionsProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleOilAndGasData implements BaseEvent {
  event_type = 'toggle oil and gas data';

  constructor(
    public event_properties: ToggleOilAndGasDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TogglePlumeEmissionData implements BaseEvent {
  event_type = 'toggle plume emission data';

  constructor(
    public event_properties: TogglePlumeEmissionDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleSpeedDial implements BaseEvent {
  event_type = 'toggle speed dial';

  constructor(
    public event_properties: ToggleSpeedDialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewRefreshDialog implements BaseEvent {
  event_type = 'view refresh dialog';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * choose global date
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/choose%20global%20date)
   *
   * Captures app-wide date selected for emissions data
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  chooseGlobalDate(
    properties: ChooseGlobalDateProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChooseGlobalDate(properties), options);
  }

  /**
   * click intro area emissions
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/click%20intro%20area%20emissions)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickIntroAreaEmissions(
    options?: EventOptions,
  ) {
    return this.track(new ClickIntroAreaEmissions(), options);
  }

  /**
   * click intro filter ogi
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/click%20intro%20filter%20ogi)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickIntroFilterOgi(
    options?: EventOptions,
  ) {
    return this.track(new ClickIntroFilterOgi(), options);
  }

  /**
   * click intro ogi
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/click%20intro%20ogi)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickIntroOgi(
    options?: EventOptions,
  ) {
    return this.track(new ClickIntroOgi(), options);
  }

  /**
   * click intro point sources
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/click%20intro%20point%20sources)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickIntroPointSources(
    options?: EventOptions,
  ) {
    return this.track(new ClickIntroPointSources(), options);
  }

  /**
   * click intro regional emissions
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/click%20intro%20regional%20emissions)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickIntroRegionalEmissions(
    options?: EventOptions,
  ) {
    return this.track(new ClickIntroRegionalEmissions(), options);
  }

  /**
   * click intro zoom
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/click%20intro%20zoom)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickIntroZoom(
    options?: EventOptions,
  ) {
    return this.track(new ClickIntroZoom(), options);
  }

  /**
   * click target drawer observation
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/click%20target%20drawer%20observation)
   *
   * Fires when the user clicks an observation from a drawer.
   *
   * @param properties The event's properties (e.g. emissionRate)
   * @param options Amplitude event options.
   */
  clickTargetDrawerObservation(
    properties: ClickTargetDrawerObservationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickTargetDrawerObservation(properties), options);
  }

  /**
   * click zoom
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/click%20zoom)
   *
   * Fired when the zoom in and zoom out buttons are pressed.
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. direction)
   * @param options Amplitude event options.
   */
  clickZoom(
    properties: ClickZoomProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickZoom(properties), options);
  }

  /**
   * filter area emissions
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/filter%20area%20emissions)
   *
   * Fired when area emissions are filtered in the settings panel
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. invisibleLayers)
   * @param options Amplitude event options.
   */
  filterAreaEmissions(
    properties: FilterAreaEmissionsProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterAreaEmissions(properties), options);
  }

  /**
   * filter operators
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/filter%20operators)
   *
   * Event fired when a user filters the oil and gas infrastructure. It is fired for each operator that is added or removed.
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. operators)
   * @param options Amplitude event options.
   */
  filterOperators(
    properties: FilterOperatorsProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterOperators(properties), options);
  }

  /**
   * filter plume emissions
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/filter%20plume%20emissions)
   *
   * Fired when plume emissions are filtered in the settings panel
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. invisibleLayers)
   * @param options Amplitude event options.
   */
  filterPlumeEmissions(
    properties: FilterPlumeEmissionsProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterPlumeEmissions(properties), options);
  }

  /**
   * ogi filter by category
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/ogi%20filter%20by%20category)
   *
   * List of objects denoting types of OGI that the map should display.
   *
   * @param properties The event's properties (e.g. categories)
   * @param options Amplitude event options.
   */
  ogiFilterByCategory(
    properties: OgiFilterByCategoryProperties,
    options?: EventOptions,
  ) {
    return this.track(new OgiFilterByCategory(properties), options);
  }

  /**
   * ogi filter by operator
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/ogi%20filter%20by%20operator)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. operators)
   * @param options Amplitude event options.
   */
  ogiFilterByOperator(
    properties: OgiFilterByOperatorProperties,
    options?: EventOptions,
  ) {
    return this.track(new OgiFilterByOperator(properties), options);
  }

  /**
   * open area emission details
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20area%20emission%20details)
   *
   * Fires when a user clicks on an area emission
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. coordinates)
   * @param options Amplitude event options.
   */
  openAreaEmissionDetails(
    properties: OpenAreaEmissionDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenAreaEmissionDetails(properties), options);
  }

  /**
   * open basin details
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20basin%20details)
   *
   * Fires when basin details opens
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  openBasinDetails(
    properties: OpenBasinDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenBasinDetails(properties), options);
  }

  /**
   * open download
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20download)
   *
   * Fires when a user opens the download pane
   *
   * Owner: Cory Mortimer Martin
   *
   * @param options Amplitude event options.
   */
  openDownload(
    options?: EventOptions,
  ) {
    return this.track(new OpenDownload(), options);
  }

  /**
   * open legend
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20legend)
   *
   * Fires when a user opens the legend
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  openLegend(
    properties: OpenLegendProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenLegend(properties), options);
  }

  /**
   * open methane layer drawer from url
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20methane%20layer%20drawer%20from%20url)
   *
   * Event to track when a user opens a methane layer drawer from the URL
   *
   * @param properties The event's properties (e.g. collectionId)
   * @param options Amplitude event options.
   */
  openMethaneLayerDrawerFromUrl(
    properties: OpenMethaneLayerDrawerFromUrlProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenMethaneLayerDrawerFromUrl(properties), options);
  }

  /**
   * open multi feature details
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20multi%20feature%20details)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. featureId)
   * @param options Amplitude event options.
   */
  openMultiFeatureDetails(
    properties: OpenMultiFeatureDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenMultiFeatureDetails(properties), options);
  }

  /**
   * open no data
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20no%20data)
   *
   * Fires when the user clicks on an area with no data. Even if the drawer is already open, this event will fire.
   *
   * Owner: Cory Mortimer Martin
   *
   * @param options Amplitude event options.
   */
  openNoData(
    options?: EventOptions,
  ) {
    return this.track(new OpenNoData(), options);
  }

  /**
   * open notifications
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20notifications)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. count)
   * @param options Amplitude event options.
   */
  openNotifications(
    properties: OpenNotificationsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenNotifications(properties), options);
  }

  /**
   * open pipeline details
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20pipeline%20details)
   *
   * Fires when a user clicks on a pipeline
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. coordinates)
   * @param options Amplitude event options.
   */
  openPipelineDetails(
    properties: OpenPipelineDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenPipelineDetails(properties), options);
  }

  /**
   * open plume emission details
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20plume%20emission%20details)
   *
   * Fires when a user clicks on a plume.
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. coordinates)
   * @param options Amplitude event options.
   */
  openPlumeEmissionDetails(
    properties: OpenPlumeEmissionDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenPlumeEmissionDetails(properties), options);
  }

  /**
   * open point infrastructure details
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20point%20infrastructure%20details)
   *
   * Fires when a user clicks on point infrastructure
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. accordion)
   * @param options Amplitude event options.
   */
  openPointInfrastructureDetails(
    properties: OpenPointInfrastructureDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenPointInfrastructureDetails(properties), options);
  }

  /**
   * open settings
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20settings)
   *
   * Fires when the user opens the settings
   *
   * Owner: Cory Mortimer Martin
   *
   * @param options Amplitude event options.
   */
  openSettings(
    options?: EventOptions,
  ) {
    return this.track(new OpenSettings(), options);
  }

  /**
   * open target details
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/open%20target%20details)
   *
   * Fires when target details opens
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  openTargetDetails(
    properties: OpenTargetDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenTargetDetails(properties), options);
  }

  /**
   * select platform
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/select%20platform)
   *
   * Fires when the user selects a platform from the dropdown in the header.
   *
   * @param properties The event's properties (e.g. platform)
   * @param options Amplitude event options.
   */
  selectPlatform(
    properties: SelectPlatformProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectPlatform(properties), options);
  }

  /**
   * show ogi near plumes
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/show%20ogi%20near%20plumes)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. count)
   * @param options Amplitude event options.
   */
  showOgiNearPlumes(
    properties: ShowOgiNearPlumesProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShowOgiNearPlumes(properties), options);
  }

  /**
   * toggle area emission data
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/toggle%20area%20emission%20data)
   *
   * Owner: Emma Bishop
   *
   * @param properties The event's properties (e.g. visible)
   * @param options Amplitude event options.
   */
  toggleAreaEmissionData(
    properties: ToggleAreaEmissionDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleAreaEmissionData(properties), options);
  }

  /**
   * toggle area emissions product
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/toggle%20area%20emissions%20product)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  toggleAreaEmissionsProduct(
    properties: ToggleAreaEmissionsProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleAreaEmissionsProduct(properties), options);
  }

  /**
   * toggle oil and gas data
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/toggle%20oil%20and%20gas%20data)
   *
   * Fires when the oil and gas layer is toggled on and off. This does **not** include the basin layer.
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. visible)
   * @param options Amplitude event options.
   */
  toggleOilAndGasData(
    properties: ToggleOilAndGasDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleOilAndGasData(properties), options);
  }

  /**
   * toggle plume emission data
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/toggle%20plume%20emission%20data)
   *
   * Owner: Emma Bishop
   *
   * @param properties The event's properties (e.g. visible)
   * @param options Amplitude event options.
   */
  togglePlumeEmissionData(
    properties: TogglePlumeEmissionDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new TogglePlumeEmissionData(properties), options);
  }

  /**
   * toggle speed dial
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/toggle%20speed%20dial)
   *
   * Fired when the speed dial is opened or closed.
   *
   * Owner: Cory Mortimer Martin
   *
   * @param properties The event's properties (e.g. open)
   * @param options Amplitude event options.
   */
  toggleSpeedDial(
    properties: ToggleSpeedDialProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleSpeedDial(properties), options);
  }

  /**
   * view refresh dialog
   *
   * [View in Tracking Plan](https://data.amplitude.com/methanesat/Data%20Portal/events/main/latest/view%20refresh%20dialog)
   *
   * Triggers when the refresh dialog is opened as a result of a session token becoming expired.
   *
   * @param options Amplitude event options.
   */
  viewRefreshDialog(
    options?: EventOptions,
  ) {
    return this.track(new ViewRefreshDialog(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
