/**
 * Types supporting map layer attributes
 */

// Typings to support GeoJSON data
// See https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/geojson/index.d.ts
import GeoJSON from 'geojson';

import { STACCollection, StacFeature } from './';

export enum AreaEmissionsProducts {
    l3 = 'l3',
    l4 = 'l4'
}
export enum MethaneEmissionsProducts {
    l3 = 'l3',
    l4 = 'l4',
    target = 'target',
    plume = 'plume'
}

export const areaEmissionsProductValues: (keyof typeof AreaEmissionsProducts)[] = Object.keys(
    AreaEmissionsProducts
) as (keyof typeof AreaEmissionsProducts)[];

export type AreaEmissionsProduct = (typeof areaEmissionsProductValues)[number];

/** All methane layer ids. */
export enum MethaneLayerIds {
    targets = 'targets',
    plumeEmissionRate = 'plume-emission-rate',
    areaEmissionRaster = 'area-emission-raster',
    areaEmissionRasterHighlight = 'highlight-for-area-emissions-raster'
}

/**
 * Methane feature types, as represented in the app url
 */
export enum UrlFeatureTypes {
    Plume = 'point-source',
    AreaEmission = 'ae',
    Target = 'target'
}
export function isUrlFeatureType(data: unknown): data is UrlFeatureTypes {
    if (typeof data !== 'string') return false;
    return Object.values(UrlFeatureTypes).includes(data as UrlFeatureTypes);
}

/**
 * Converts the app's url parameter `feature-type` to its corresponding layer id
 */
export const UrlFeatureTypeToLayerId = new Map<UrlFeatureTypes, MethaneLayerIds>([
    [UrlFeatureTypes.Plume, MethaneLayerIds.plumeEmissionRate],
    [UrlFeatureTypes.AreaEmission, MethaneLayerIds.areaEmissionRaster],
    [UrlFeatureTypes.Target, MethaneLayerIds.targets]
]);

/**
 * Converts a methane layer id into the corresponding value for url parameter `feature-type`
 * inversion of UrlFeatureTypeToLayerId
 */
export const LayerIdToUrlFeatureType = new Map(
    Array.from(UrlFeatureTypeToLayerId, (a) => a.reverse() as [MethaneLayerIds, UrlFeatureTypes])
);

export interface LoadedData {
    [key: string]: number | string;
}

export interface TargetTypeProperties {
    /** Unique ID for this specific target. */
    targetId?: string;
    target_id: string;
    /** Collection ID that the target belongs to. */
    collectionId?: STACCollection;
    /** GeoJSON coordinates of the target's geometry */
    coordinates?: GeoJSON.Position[];
}
export type TargetTypeFeature = GeoJSON.Feature<GeoJSON.Polygon, TargetTypeProperties>;

export interface TargetFeatureProperties extends TargetTypeProperties {
    /** Instrument used for this measurement. */
    instrument?: string;
    /** Location where the measurement was taken. */
    location?: string;
    /** Total size of the target in km². */
    sizeKm2?: number;
    /** Unique ID for this specific target. */
    targetId?: string;
    /** End of the data capture interval in ISO-8601 UTC format. */
    timeEnd?: string;
    /** Start of the data capture interval in ISO-8601 UTC format. */
    timeStart?: string;
    /** Upper confidence interval in kg/hr. */
    totalHighKgHr?: number;
    /** Total measured emissions of the entire target. */
    totalKgHr?: number;
    /** Lower confidence interval in kg/hr. */
    totalLowKgHr?: number;
    /** Total number of discernable plumes in the target. */
    pointSourceCount?: number;
    /**
     * Sum total kg/hr of all discernable plumes in the target, used to determine their contribution
     * relative to overall area emissions.
     */
    pointSourceTotalKgHr?: number;
}
export type TargetFeature = GeoJSON.Feature<GeoJSON.Polygon, TargetFeatureProperties>;

// updated Capture Type
export interface CaptureFeatureProperties extends TargetTypeProperties {
    /** Unique ID for this specific target. */
    target_id: string;
    /** Basin where capture taken */
    basin?: string;
    location?: string;
    /** Descriptive capture title */
    title?: string;
    /** Description */
    description?: string;
    /** States covered by capture */
    states?: string[];
    /** Country locating capture */
    country?: 'string';
    /** Total number of discernable plumes in the capture. */
    ps_count?: number;
    /**
     * Sum total kg/hr of all discernable plumes in the capture, used to determine their contribution
     * relative to overall area emissions.
     */
    ps_total?: number;
    /** Total size of the capture in km². */
    size_km2?: number;
    /** Total measured emissions of the entire capture. */
    net_total?: number;
    /** Total measured emissions of just the area emissions. */
    area_total?: number;
    /** Instrument used for this measurement. */
    instrument: string;
    /** Unique ID for this specific capture. */
    capture_id?: string;
    /** Upper confidence interval in kg/hr. */
    net_total_high?: number;
    /** Lower confidence interval in kg/hr. */
    net_total_low?: number;
    /** date of capture */
    datetime: string;
    /** End of the data capture interval in ISO-8601 UTC format. */
    end_datetime: string;
    /** Start of the data capture interval in ISO-8601 UTC format. */
    start_datetime: string;
}
export type CaptureFeature = StacFeature & GeoJSON.Feature<GeoJSON.Polygon, CaptureFeatureProperties>;
export type CaptureFeatureCollection = GeoJSON.FeatureCollection<GeoJSON.Polygon, CaptureFeatureProperties>;

export function isCaptureFeature(data: unknown): data is CaptureFeature {
    if (!data || typeof data !== 'object') return false;
    if (Array.isArray(data)) return false;
    return 'geometry' in data && 'properties' in data && isCaptureProperties(data.properties);
}

/**
 * If the proprties are of a Capture
 * @param data
 * @returns boolean if data is a capture
 */
export function isCaptureProperties(data: unknown): data is CaptureFeatureProperties {
    if (!data || typeof data !== 'object') return false;
    return 'target_id' in data;
}

/**
 * Mean emissions in a target (mean = total emissions / target size)
 */
export type kgHrKm2 = number;

/**
 * Total emissions. Used for targets
 */
export type kgHr = number;

export enum EmissionUnits {
    kgHr = 'kgHr',
    kgHrKm2 = 'kgHrKm2'
}
