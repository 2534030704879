import { RASTER_COLORMAP_NAMES, getRasterColorRange, CONCENTRATION_RANGE, dataSources } from '../../consts';
import { STACCollection, Platforms } from '../../types';

/**
 *
 * @param itemID STAC item id
 * @param collectionName STAC collection id
 * @param colormapName optional raster colormap to pass to TiTiler; **default** = `'colormap_name=magma&rescale=0,1000'`
 * @param platform **default** = Platforms.MAIR
 * @returns url string for TiTiler
 */
export const getStacItemCOGUrl = (
    itemID: string,
    collectionName: STACCollection = STACCollection.MethaneAIR_Level4,
    colormapName: string | undefined | RASTER_COLORMAP_NAMES = 'colormap_name=magma&rescale=0,1000',
    platform: Platforms = Platforms.MAIR
) => {
    const { MIN, MAX } = getRasterColorRange(platform);
    const isConcentrations = collectionName.includes('Level3');
    if (isConcentrations) {
        colormapName = RASTER_COLORMAP_NAMES.msatFlirLog;
    }

    /**
     * rescale: sets min-max inputs for linearly outputting to 0-255 color bins.
     * See https://github.com/developmentseed/titiler/discussions/494#discussioncomment-3113805
     * Do not use for MSAT.
     */
    let rescale = isConcentrations
        ? `rescale=${CONCENTRATION_RANGE[0]},${CONCENTRATION_RANGE[1]}`
        : `rescale=${MIN},${MAX}`;
    rescale = platform === Platforms.MAIR ? `&${rescale}&nodata=nan` : '';
    const urls = getStacDataSource();
    return `${urls.titilerUrl}/collections/${collectionName}/items/${itemID}/tiles/WebMercatorQuad/{z}/{x}/{y}@2x.png?assets=COG&colormap_name=${colormapName}${rescale}`;
};

/**
 * Return urls for emissions data
 * @returns area emissions raster url
 */
export const getStacDataSource = () => {
    return dataSources['area-emission-raster'].url;
};
