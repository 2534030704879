import { MapStyles } from '@methanesat/maps';
import { DateOption } from '@methanesat/ui-components';

import { isPlatform, Platforms, STACCollection, UrlFeatureTypes } from '../types';
import { getDateRangeEnd, getDateRangeFromPlatform, getDateRangeStart } from './stac/stacSearch';
import { getMakesValidDate, isDate } from './time';

export type QueryStringObject = Record<string, string>;

/**
 * Defines the information needed to add one piece of state to
 * the querystring correctly and later, parse the querystring (could be comma-separated)
 * and load the parsed data into the store.
 */
export type StringValidator = (validateItem: string) => boolean;
export type ObjectValidator = (validateItem: QueryStringObject) => boolean;

/**
 * Checks if the URL date parameter is a valid date, is in the format YYYY-MM-DD,
 * is in the date picker, and is not before 2020 or in the future.
 */
export function validateUrlGlobalDateString(unsanitizedDateString: string, dateOptions: DateOption[]) {
    const isValidDateFormat = validateUrlDateString(unsanitizedDateString);
    if (!isValidDateFormat) return false;

    const utcDate = new Date(`${unsanitizedDateString} UTC`);
    const sanitizedDateString = getSantitizedURLDateFormat(unsanitizedDateString);

    const isInTimeRange = 2020 <= utcDate.getFullYear() && utcDate.getTime() <= Date.now();
    const isInDatePicker = dateOptions.some(
        (dateOption) => getSantitizedURLDateFormat(dateOption.value) === sanitizedDateString
    );

    return isValidDateFormat && isInTimeRange && isInDatePicker;
}

/**
 * Checks that the URL feature-date parameter is a valid date in the format YYYY-MM-DD
 * and is within the range of the global date selected
 */
export const validateUrlFeatureDateString = (
    unsanitizedDateString: string,
    globalDate: number,
    platform: Platforms
) => {
    const isValidDateFormat = validateUrlDateString(unsanitizedDateString);
    if (!isValidDateFormat) return false;

    const dateRange = getDateRangeFromPlatform(platform);

    const utcDate = new Date(`${unsanitizedDateString} UTC`);
    const utcNumber = utcDate.getTime();

    // get start & end of platform-specific time ranges
    const rangeEnd = getDateRangeEnd(globalDate, dateRange);
    const rangeStart = getDateRangeStart(globalDate, dateRange);

    const isInTimeRange = rangeStart.getTime() <= utcNumber && utcNumber <= rangeEnd.getTime();
    return isInTimeRange;
};

/**
 * Checks that the URL feature-date parameter is a valid date in the format YYYY-MM-DD
 */
export const validateUrlDateString = (unsanitizedDateString: string) => {
    // Regular expression pattern for YYYY-MM-DD
    const regex = /^\d{4}-\d{1,2}-\d{1,2}$/;
    const utcDate = new Date(`${unsanitizedDateString} UTC`);
    // Check if valid date before calling toISOString
    if (isNaN(utcDate.getTime())) return false;
    const sanitizedDateString = utcDate.toISOString().split('T')[0];

    return regex.test(sanitizedDateString);
};

/**
 * Checks if the platform parameter is either "MethaneSAT or MethaneAIR"
 */
export function validateUrlPlatformParam(unsanitzedPlatform: string): unsanitzedPlatform is Platforms {
    return isPlatform(unsanitzedPlatform);
}

/**
 * Checks if the URL collection-id parameter is a contained within the STACCollection enum.
 */
export function validateUrlCollectionId(unsanitizedCollectionId: string): unsanitizedCollectionId is STACCollection {
    return Object.values(STACCollection).includes(unsanitizedCollectionId as STACCollection);
}

/**
 * Used for STAC itemId and target ids.
 *
 * This only checks that the parameter is a non-empty string since
 * naming conventions for STAC items are still in the works. Once those are finalized
 * stricter validation should be implemented.
 */
export function validateUrlString(unsanitizedString: string) {
    return typeof unsanitizedString === 'string' && unsanitizedString.length > 0;
}

/**
 * Checks if the URL feature-type parameter is a valid feature type.
 */
export function validateUrlFeatureType(unsanitizedFeatureType: string) {
    return Object.values(UrlFeatureTypes).includes(unsanitizedFeatureType as UrlFeatureTypes);
}

export function validateUrlViewState(viewState: QueryStringObject) {
    for (const [key, value] of Object.entries(viewState)) {
        const newValue = parseFloat(value);
        if (isNaN(newValue) || (key === 'latitude' && Math.abs(newValue) > 90)) {
            return false;
        }
    }
    return true;
}

export function validateUrlMapStyles(mapStyle: string) {
    return Object.values(MapStyles).includes(mapStyle as (typeof MapStyles)[keyof typeof MapStyles]);
}

export const getSantitizedURLDateFormat = (input: unknown) => {
    let utcDate: Date | null = null;

    const makesValidDate = getMakesValidDate(input);
    if (!makesValidDate) return null;

    if (typeof input === 'string') {
        utcDate = new Date(`${input} UTC`);
    } else if (input && typeof input === 'object' && isDate(input)) {
        utcDate = new Date(input);
    } else if (typeof input === 'number') {
        utcDate = new Date(input);
    }
    if (utcDate && isDate(utcDate)) {
        return utcDate.toISOString().split('T')[0];
    }
    return null;
};
