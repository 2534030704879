import { load, Loader } from '@loaders.gl/core';
import { Layer } from '@methanesat/maps';

import { IN_IAP } from '../environmentVariables';
import { APIResponse, Company, Country, HTTPResponseError, ScorecardData, ScorecardList, UserType } from '../types';
import { Notifier } from '../utils/Notifier';

/**
 * A wrapper around fetch that handles app specific behavior, such as redirecting
 * the user to the home page when a redirect response appears.
 */
export const customFetch = async (url: RequestInfo, options?: RequestInit) => {
    // Google sends a redirect code when the user should login. When setting
    // redirect: manual, we can control what should happen when we see a
    // redirect.
    let response;
    try {
        response = await fetch(url, {
            ...options,
            headers: {
                // By default, IAP returns 302 on unauthorized. This
                // makes it so it returns a 401.
                // https://cloud.google.com/iap/docs/external-identity-sessions#handling_ajax_requests
                ...(IN_IAP && { 'X-Requested-With': 'XMLHttpRequest' }),
                ...options?.headers
            }
        });
    } catch (e) {
        const err = new Error(`Error fetching ${url}:\n${e}`);
        err.stack = (e as Error).stack;
        err.name = (e as Error).name;
        throw err;
    }

    if (IN_IAP && response.status === 401) {
        /**
         * On a 401, show the refresh dialog, forcing the user
         * to refresh the page to re-authenticate with IAP.
         */
        new Notifier().notify();
    }

    return response;
};

/**
 * Returns the custom fetch function needed to handle app specific data for layers.
 */
const getCustomLoadOptions = (loadOptions: unknown) => {
    let passedInOptions = {};
    if (typeof loadOptions !== 'number' && !!loadOptions) {
        passedInOptions = loadOptions;
    }

    let fetchOptions = {};
    if ('fetch' in passedInOptions && typeof passedInOptions.fetch !== 'number' && !!passedInOptions.fetch) {
        fetchOptions = passedInOptions.fetch;
    }

    return {
        ...passedInOptions,
        fetch: (url: string) => customFetch(url, { ...fetchOptions })
    };
};

/**
 * A custom fetch function to be provided to deck gl layers so that the layers can respond
 * to app specific behavior, such as redirecting to the home page when a redirect response appears.
 * All code is exactly identical to the deck gl code except for one place where we need access
 * to the signal variable:
 * https://github.com/visgl/deck.gl/blob/v8.8.26/modules/core/src/lib/layer.ts#L89
 */
export const fetchForLayers = async <LayerT extends Layer<Record<string, string>>>(
    url: string,
    {
        propName,
        layer,
        loaders,
        loadOptions,
        signal
    }: {
        propName: string;
        layer: LayerT;
        loaders?: Loader[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        loadOptions?: any;
        signal?: AbortSignal;
    }
) => {
    const { resourceManager } = layer.context;
    loadOptions = loadOptions || layer.getLoadOptions();
    loaders = loaders || layer.props.loaders;
    if (signal) {
        loadOptions = {
            ...loadOptions,
            fetch: {
                ...loadOptions?.fetch,
                signal
            }
        };
    }

    // This should be the only change that differs from deck gl. The fetch code needed
    // a way to get access to the signal that is passed in.
    const customLoadOptions = getCustomLoadOptions(loadOptions);

    const inResourceManager = resourceManager.contains(url);

    if (!inResourceManager && !loadOptions) {
        // If there is no layer-specific load options, then attempt to cache this resource in the data manager
        resourceManager.add({
            resourceId: url,
            data: load(url, loaders, customLoadOptions),
            persistent: false
        });
    }
    if (inResourceManager) {
        return resourceManager.subscribe({
            resourceId: url,
            onChange: (data) => layer.internalState?.reloadAsyncProp(propName, data),
            consumerId: layer.id,
            requestId: propName
        });
    }

    return load(url, loaders, customLoadOptions);
};

/**
 * Temporary util to fake API responses with a configurable delay.
 */
export const stubAPIFetch = (response: APIResponse, delayMS = 0, error?: HTTPResponseError): Promise<APIResponse> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (typeof error !== 'undefined') {
                reject(error);
            }

            resolve(response);
        }, delayMS);
    });
};

/**
 * Temporary util to fake HTTP errors.
 */
export const stubAPIFetchErroring = (errorObject: HTTPResponseError): Promise<HTTPResponseError> => {
    return stubAPIFetch({}, 0, errorObject);
};

/**
 * Fetch list of scorecard entities (countries and companies).
 * @todo Connect to API, remove stub.
 */
export const fetchScorecardList = async (): Promise<ScorecardList> => {
    const companies: Company[] = [
        {
            name: 'Roxxon Energy Corporation',
            slug: 'roxxon-energy-corporation'
        },
        {
            name: 'Hexus',
            slug: 'hexus'
        }
    ];

    const countries: Country[] = [
        {
            name: 'China',
            slug: 'china'
        },
        {
            name: 'United States',
            slug: 'united-states'
        }
    ];

    return stubAPIFetch({ companies, countries }) as Promise<ScorecardList>;
};

/**
 * Fetches a scorecard for a single company or country.
 * @todo Connect to API, remove stub.
 */
export const fetchScorecard = async (entityId: string): Promise<ScorecardData> => {
    type StubEntities = { [key: string]: string };
    const stubEntities: StubEntities = {
        ['roxxon-energy-corporation']: 'Roxxon Energy Corporation',
        hexus: 'Hexus'
    };

    const stubData: ScorecardData = {
        name: stubEntities[entityId],
        slug: entityId,
        type: 'company',
        website: 'https://example.com'
    };

    return stubAPIFetch(stubData) as Promise<ScorecardData>;
};

/** Temporary stub user data. */
const stubUser: UserType = {
    id: 123,
    givenName: 'Walter',
    familyName: 'White',
    avatarSrc: 'https://en.wikipedia.org/wiki/Walter_White_(Breaking_Bad)#/media/File:Walter_White_S5B.png'
};

/** Temporary stub HTTP error. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stubErrorMessage: HTTPResponseError = {
    code: 403,
    message: 'Access denied.'
};

export const fetchCurrentUser = async (): Promise<APIResponse> => {
    const user = await stubAPIFetch(stubUser);
    //const user = await stubAPIFetchErroring(stubErrorMessage);  // uncomment to test out an erroring API
    return user;
};

/**
 * Determines if the error object is in the expected error object format.
 * User-defined type guard, see https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 */
export const isHTTPError = (error: HTTPResponseError | unknown): error is HTTPResponseError => {
    return (error as HTTPResponseError).code !== undefined && (error as HTTPResponseError).message !== undefined;
};
