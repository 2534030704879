import GeoJSON from 'geojson';

import {
    creamYellow,
    darkTealTransparent60,
    darkTealTransparent20,
    grey,
    lightGrey,
    orangeTransparent50,
    RGB,
    skyBlueTransparent50,
    white,
    whiteTransparent50,
    whiteTransparent60
} from '@methanesat/colors';

import { DISABLE_LAYER_ERRORS } from '../environmentVariables';
import { MapStateLayerNames, MethaneLayerIds, OGILayerIds, Platforms, ViewStatePropsRedux } from '../types';

export const GoogleBasemaps = {
    /**
     * Roadmap light.
     * Defined in msat-prod-portal, see https://console.cloud.google.com/google/maps-apis/studio/maps?hl=en&project=msat-prod-portal-a1ae
     */
    roadmapLight: {
        id: '5bb09edbfa609fe2', // clean vector
        type: 'vector'
    }
};

// map colors
export const areaHighlightColor = whiteTransparent50;
export const areaIconHighlightColor = white;
export const basinLineColor = grey;
export const basinHighlightColor = skyBlueTransparent50;
export const ogiColor = darkTealTransparent60;
export const pipelineColor = darkTealTransparent20;
export const pipelineHighlightColor = whiteTransparent50;
export const plumeColor = creamYellow;
export const plumeHighlightColor = orangeTransparent50;
export const pointInfrastructureColor = ogiColor;
export const pointInfrastructureLineColor = darkTealTransparent20;
export const pointInfrastructureHighlightColor = whiteTransparent50;
export const targetHighlightColor = whiteTransparent60;

// When displying colors with an alpha channel outside of
// the map, we want to create a color from the map background
// color to display consistently against different backgrounds.
export const genericMapBackground = [252, 250, 249] as RGB; // #FCFAF9

/**
 * NOTE: should be a whole number (we floor the number to avoid excessive HTTP requests).
 */
const ZOOM_TARGET_THRESHOLD = 6;

export const ZOOM_THRESHOLDS = {
    // infrastructure and similar thresholds
    MAXIMUM_ZOOM_LEVEL_TARGET: ZOOM_TARGET_THRESHOLD,
    MINIMUM_ZOOM_LEVEL_BASINS: 4,
    MAXIMUM_ZOOM_LEVEL_BASINS: 9,
    MINIMUM_ZOOM_LEVEL_OGI: 10,

    // methane zoom level thresholds
    // Note: this needs to be a WHOLE number (it's Math.floor'ed in the implementation).
    MINIMUM_ZOOM_LEVEL_AREA_FLUX_GRID: ZOOM_TARGET_THRESHOLD,

    // plume zoom levels
    MINIMUM_ZOOM_LEVEL_PLUME_FLUX: ZOOM_TARGET_THRESHOLD,

    // zoom level for icon layer on targets
    MAXIMUM_ZOOM_LEVEL_TARGET_MULTI_CAPTURE_ICON: ZOOM_TARGET_THRESHOLD,
    MINIMUM_ZOOM_LEVEL_TARGET_MULTI_CAPTURE_ICON: 4.25,

    // zoom level for date icon layer on targets
    MAXIMUM_ZOOM_LEVEL_TARGET_DATE_LABEL: ZOOM_TARGET_THRESHOLD,
    MINIMUM_ZOOM_LEVEL_TARGET_DATE_LABEL: 5.5
};

/** sets max zoom level - map cannot be zoomed in past this level */
// Per DP-2733 set max zoom to eliminate striping at zooms higher than 16.5
export const maxZoomBaseMap = 16.4;

/**
 * Set max zoom for OGI tile data. This must match the max zoom available in
 * the tileset.
 *
 * This will allow users to over-zoom in the map and deck.gl will
 * not request new tilesets past this number.  Tiles for this zoom will remain
 * visible at higher zoom levels. If left unset, deck.gl will request tiles past this zoom
 * level and recieve empty tiles.
 */
export const maxZoomOGITiles = 14;

/**
 * Set max zoom for plume tile data. This must match the max zoom available in
 * the tileset.
 *
 * This will allow users to over-zoom in the map and deck.gl will
 * not request new tilesets past this number.  Tiles for this zoom will remain
 * visible at higher zoom levels. If left unset, deck.gl will request tiles past this zoom
 * level and recieve empty tiles.
 */
export const maxZoomPlumeTiles = 14;

/**
 * Zoom levels that the map snaps to given a feature type
 *
 * Note: These zoom levels were determined based on what looked best to
 * the developer. These settings are subjective, so feel free to adjust the
 * zoom levels as needed to better suit preferences or needs.
 */
export const SNAP_ZOOM_LEVELS_FOR_FEATURE_TYPES = {
    TARGET: 5.9,
    PLUME_EMISSION_RATE: 8.6,
    AREA_EMISSION_RASTER: 8.25
};

/**
 * Mapping that assigns each feature type a zoom level to snap to
 */
export const zoomLevelToFeatureTypeMap: { [key in MethaneLayerIds]: number } = {
    [MethaneLayerIds.targets]: SNAP_ZOOM_LEVELS_FOR_FEATURE_TYPES.TARGET,
    [MethaneLayerIds.plumeEmissionRate]: SNAP_ZOOM_LEVELS_FOR_FEATURE_TYPES.PLUME_EMISSION_RATE,
    [MethaneLayerIds.areaEmissionRaster]: SNAP_ZOOM_LEVELS_FOR_FEATURE_TYPES.AREA_EMISSION_RASTER,
    [MethaneLayerIds.areaEmissionRasterHighlight]: SNAP_ZOOM_LEVELS_FOR_FEATURE_TYPES.AREA_EMISSION_RASTER
};

/** Latitude/longitude object. */
interface LatLng {
    latitude: number;
    longitude: number;
}

/**
 * Center coordinates of geographic areas.
 */
export const geographicCenters: Record<string, LatLng> = {
    permian: {
        longitude: -103.17659,
        latitude: 31.87696
    },
    RF06: {
        longitude: -103.68107,
        latitude: 32.00539
    },
    US: {
        /**
         * Geographic center of the US needs to be adjusted slightly to center the map better in the viewport.
         * Actual center is [-103.771556, 44.967243]
         * https://en.wikipedia.org/wiki/Geographic_center_of_the_United_States
         */
        longitude: -103.8,
        latitude: 40
    },
    uintaAndPermian: {
        /** Beta 1: Centered on Uinta (RF08) and Permian (RF06). */
        longitude: -106.849,
        latitude: 35.62
    },
    /** The actual center is at [0,0], but the view state is shifted northward for improved visibility */
    world: {
        longitude: 0,
        latitude: 25
    }
};

// Whole world bounding box [180, -90, 180, 90] behaves as if no bounding box was applied.
// This actually restricts left and right panning.
export const wholeWorldBbox: GeoJSON.BBox = [-179, -90, 180, 90];

const standardOrientation = { bearing: 0, pitch: 0, altitude: 1.5 };

/**
 * Accounts for horizontal space taken up by the Intro Drawer which is open by default on initial load.
 * Tied to zoom ~3.3.
 */
export const longitudePaddingForOpenDrawer = 0;

/**
 * Accounts for vertical space taken up by the global date picker.
 * Tied to zoom ~3.3.
 */
export const latitudePaddingForGlobalDatePicker = -2;

export const initialViewStateMAIR: ViewStatePropsRedux = {
    ...standardOrientation,
    latitude: 38.5,
    longitude: -96,
    zoom: 4.3
};

export const initialViewStateMSAT: ViewStatePropsRedux = {
    ...standardOrientation,
    latitude: geographicCenters.world.latitude,
    longitude: geographicCenters.world.longitude,
    zoom: 1.8
};

export const initialViewStates = {
    [Platforms.MAIR]: initialViewStateMAIR,
    [Platforms.MSAT]: initialViewStateMSAT
};

export const defaultGeoJsonLayerConfig = {
    getLineColor: lightGrey,
    getLineWidth: 1,
    getFillColor: white,
    lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
    lineWidthMinPixels: 2,
    lineWidthUnits: 'meters',
    getPointRadius: 400,
    pointRadiusMaxPixels: Number.MAX_SAFE_INTEGER,
    pointRadiusMinPixels: 1,
    pointRadiusUnits: 'meters',
    enabled: true
} as const;

// thresholds by layer id
export const layerZoomThresholds: {
    [Property in MapStateLayerNames]?: {
        minimum?: number;
        maximum?: number;
    };
} = {
    // infrastructure
    [OGILayerIds.pointInfrastructure]: {
        minimum: ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_OGI
    },
    [OGILayerIds.pipelines]: {
        minimum: ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_OGI
    },
    [OGILayerIds.basins]: {
        minimum: ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_BASINS,
        maximum: ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_BASINS
    },
    [MethaneLayerIds.targets]: {
        maximum: ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_TARGET
    },
    // methane flux data
    [MethaneLayerIds.areaEmissionRaster]: {
        minimum: ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_AREA_FLUX_GRID
    },
    [MethaneLayerIds.plumeEmissionRate]: {
        minimum: ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_PLUME_FLUX
    }
};

export const LAYERS_ALLOWING_MULTIPLE_FEATURE_SELECTION = [OGILayerIds.tileInfrastructure] as const;
export type MultipleSelectionsAllowedLayerIds = (typeof LAYERS_ALLOWING_MULTIPLE_FEATURE_SELECTION)[number];
export function isLayerIdAllowingMultipleSelections(layerId: string): layerId is MultipleSelectionsAllowedLayerIds {
    return LAYERS_ALLOWING_MULTIPLE_FEATURE_SELECTION.includes(layerId as MultipleSelectionsAllowedLayerIds);
}

export const LAYER_ERRORS_ENABLED = !DISABLE_LAYER_ERRORS;

export const SELECTED_AREA_EMISSION_ICON_PATH = '/img/crosshairs-icon.svg';
