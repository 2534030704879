import { Feature, GeoJsonProperties, Geometry } from 'geojson';

import { CalendarData } from '@methanesat/ui-components';

import { Platforms, UrlFeatureTypes } from './';

/**
 * Available STAC Collections (see `/stac/collections` endpoint)
 */
export enum STACCollection {
    MethaneAIR_Level3 = 'MethaneAIR_Level3',
    MethaneAIR_Level4 = 'MethaneAIR_Level4',
    MethaneSAT_Level3 = 'MethaneSAT_Level3',
    MethaneSAT_Level4 = 'MethaneSAT_Level4'
}
export function isSTACCollectionId(data: unknown): data is STACCollection {
    if (typeof data !== 'string') return false;
    const ids = Object.values(STACCollection);
    return ids.includes(data as STACCollection);
}
/**
 * Properties returned from stac raster details search
 * in `hooks/data/stacData/stac.tsx`:
 * `getRasterPointDataFromStac`
 */
// types & type guards specific to geojson from l4 diffuse data
export interface RasterPointProperties {
    basin?: string;
    collectionEndTime: string;
    collectionId: STACCollection;
    collectionStartTime: string;
    methane: number;
    sceneId: string;
    source: string;
}
export type RasterPointFeature = GeoJSON.Feature<GeoJSON.Point, RasterPointProperties> & {
    id: string;
};
export function isRasterPointProperties(data: unknown): data is RasterPointProperties {
    if (!data || typeof data !== 'object') return false;
    return 'methane' in data;
}

/**
 * Properties attached to plume geojson
 */
export interface PlumeProperties {
    start_datetime: string;
    end_datetime: string;
    instrument: string;
    basin: string;
    flux: number;
    flux_lo?: number;
    flux_hi?: number;
    // basin or other human-readable location string
    location: string;
}
export type PlumeFeature = GeoJSON.Feature<GeoJSON.Point, PlumeProperties>;
export function isPlumeProperties(data: unknown): data is PlumeProperties {
    if (!data || typeof data !== 'object') return false;
    return 'flux' in data && 'start_datetime' in data && 'end_datetime' in data;
}

/**
 * Params required in the app URL
 * to highlight an emissions feature on load
 */
export type SelectedFeatureUrlParams = {
    lat: number;
    lng: number;
    date: number;
    platform: Platforms;
    collectionId: STACCollection;
    itemId: string;
    featureType: UrlFeatureTypes;
    targetDate: string;
    targetId: string;
};

/**
 * List of Fields to include/exclude
 */
export type StacSearchField = {
    /** fields to include */
    include?: string[];
    /** fields to exclude */
    exclude?: string[];
};

/**
 * Enum for maintaining directions for search
 */
export enum SortDirection {
    Ascending = 'asc',
    Decending = 'desc'
}

/**
 * Object to specify how to sort results
 */
export type StacSearchSorter = {
    /** field: field names to use to sort result */
    field: string;
    /** direction the field should be sorted */
    direction: SortDirection;
};

export type StacFilterProperty = {
    property: string;
};

// There are a multitiude of other options that can go here
// These should be added later when/as needed
export type StacFilterValue = string;

export type StacFilterArg = StacFilterProperty | [StacFilterProperty, StacFilterValue];

export type StacFilter = {
    // filter operation
    op: string;
    args: StacFilterArg;
};

/**
 * Search Json to send to Stac to return Items
 * for more information about STAC properties and structure
 * for searches see:
 * https://github.com/radiantearth/stac-spec/blob/master/item-spec/item-spec.md
 */
export type StacSearch = {
    /** collections to search on */
    collections?: string[];
    /** ids to search on */
    ids?: string[];
    /** the number of results to return */
    limit?: number;
    /** Bounding box in the form of: min lng, min lat, max lng, max lat */
    bbox?: StacFormattedBbox;
    /** Which fields to return from the search*/
    fields?: StacSearchField;
    /** Filter results by properties and features */
    filter?: StacFilter;
    /** language used by the filter */
    'filter-lang'?: string;
    /** Which parameters to sort the results by */
    sortby?: StacSearchSorter[];
    /** Time range to return results from "from_date/to_date"
     * ex: YYYY-MM-DDTHH:mm:SSZ/YYYY-MM-DDTHH:mm:SSZ
     */
    datetime?: string;
    /** Point the search should intersect with */
    intersects?: StacSearchIntersect;
};

/**
 * Link to Stac Item
 */
export type StacItemLink = {
    rel?: string;
    href?: string;
    type?: string;
};

/**
 * Asset definition
 */
export type StacAsset = {
    href: string;
    type: string;
    title?: string;
    roles?: string[];
};

export type StacFeature<G extends GeoJSON.Geometry = GeoJSON.Geometry, P = GeoJsonProperties> = Feature<G, P> & {
    collection: STACCollection;
    stac_version?: string;
    id: string;
    links?: StacItemLink[];
    assets?: {
        COG?: StacAsset;
        GeoJSON?: StacAsset;
    };
    // Index in a Feature Collection
    index?: number;
    instrument: string;
};

/**
 * Modified Feature Collection returned from Stac Search
 */
export type StacSearchResponse<G extends Geometry = Geometry, P = GeoJsonProperties> = {
    type: string;
    features?: StacFeature<G, P>[];
    links?: StacItemLink[];
    context?: {
        limit?: number;
        returned?: number;
    };
    collection: STACCollection;
    id: string;
};

/**
 * Geometry the search should intersect with
 */
export type StacSearchIntersect = {
    type: string;
    coordinates?: number[] | number[][];
    bbox?: number[];
};

/**
 * [minLng, minLat, maxLng, maxLat]
 * aka
 * [west, south, east, north]
 */
export type StacFormattedBbox = [number, number, number, number];

export interface STACCalendarData extends CalendarData {
    collection: STACCollection;
}
