import { BitmapLayer } from '@deck.gl/layers';
import { TileLayer, TileLayerProps } from './tileLayer';
import { getCOGOpacity } from '../utils/layers';

type BoundingBox = {
    west: number;
    south: number;
    east: number;
    north: number;
};

export interface STACTileLayer extends TileLayer {
    itemId: string;
    targetId: string;
}

/**
 * Return a raster layer from Titiler
 */
export const getTitilerPgStacLayer = (
    dataUrl: string,
    id: string,
    zoom: number,
    TileLayerProps: Partial<TileLayerProps>,
    bounds: number[],
    itemId: string,
    targetId: string,
    visible: boolean = true
) => {
    // Return layer
    const tileLayer = new TileLayer({
        id,
        data: dataUrl,
        minZoom: 0,
        maxZoom: 22,
        tileSize: 512,
        extent: bounds,
        opacity: getCOGOpacity(Math.floor(zoom)),
        zoomOffset: 0,
        pickable: true,
        visible,
        // When zooming in and out, this option does not overlap
        // old tiles with new ones. The default strategy would overlay
        // old tiles with new tiles and since the colors are semi-transparent,
        // there was an artifact of darker tiles appearing.
        // https://deck.gl/docs/api-reference/geo-layers/tile-layer#refinementstrategy
        refinementStrategy: 'no-overlap',
        renderSubLayers: (props) => {
            const { west, south, east, north } = props.tile.bbox as BoundingBox;

            return [
                new BitmapLayer(props, {
                    data: undefined,
                    image: props.data,
                    bounds: [west, south, east, north],
                    pickable: true
                })
            ];
        },
        ...TileLayerProps
    }) as STACTileLayer;

    // set new tile layer's itemId to the STAC item's id
    // this assigns a new prop to the layer and
    // allows getting the STAC item's data on click
    tileLayer.itemId = itemId;
    tileLayer.targetId = targetId;

    return tileLayer;
};
