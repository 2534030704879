import { ColorRange } from '../types';

/**
 * 6-stop kepler.gl purple.
 */
export const keplerPurple: ColorRange = [
    [237, 248, 251], // #edf8fb
    [191, 211, 230], // #bfd3e6
    [158, 188, 218], // #9ebcda
    [140, 150, 198], // #8c96c6
    [136, 86, 167], // #8856a7
    [129, 15, 124] // #810f7c
];

/** 4-stop FLIR-like palette, estimated */
export const sunset4: ColorRange = [
    [7, 0, 136], // #070088
    [163, 6, 155], // #a3069b
    [255, 168, 38], // #ffa826
    [237, 251, 89] // ##edfb59
];

/** 5-stop FLIR-like palette.
 * Uses the colors above (at 0%, 25%, 75% & 100%),
 * with a 5th interpolated at 50% using https://cssgradient.io/ */
export const sunset5: ColorRange = [
    [7, 0, 136], // #070088
    [163, 6, 155], // #a3069b
    [209, 87, 97], // #d15761
    [255, 168, 38], // #ffa826
    [237, 251, 89] // #edfb59
];

/** 5-stop FLIR-like palette.
 * Uses colors from the 4-stop palette.
 * https://projects.susielu.com/viz-palette?colors=[%22#090179%22,%22#a0068f%22,%22#cc4f63%22,%22#fa9d35%22,%22#fdd500%22]&backgroundColor=%22#cccccc%22&fontColor=%22black%22&mode=%22normal%22
 */
export const sunset5WithoutVibrantYellow: ColorRange = [
    [9, 1, 121, 127], // #09017980
    [160, 6, 143, 255], // #a0068f
    [204, 79, 99, 255], // #cc4f63
    [250, 157, 53, 255], // #fa9d35
    [253, 213, 0, 255] // #fdd500
];

/**
 * 11-stop FLIR-like palette. Uses colors from sunset5WithoutVibrantYellow
 * but adds interpolated colors, particularly in the orange-yellow range.
 */
export const sunset11: ColorRange = [
    [9, 1, 121], // #090179
    [160, 6, 143], // #a0068f
    [182, 43, 121], // #b62b79
    [204, 79, 99], // #cc4f63
    [218, 102, 85], // #da6655
    [233, 128, 70], // #e98046
    [250, 157, 53], // #fa9d35

    [251, 170, 41], // #fbaa29
    [252, 184, 28], // #fcb81c
    [252, 198, 15], // #fcc60f
    [253, 213, 0] // #fdd500
];

/** 2 stop linear color palette used for both MAIR & MSAT targets.  */
export const warm2Targets: ColorRange = [
    [249, 237, 59], // #F9ED3B
    [215, 76, 43] // #D74C2B
];

/** 14 stop, non-linear color palette used for MSAT dispersed sources. Uses the
 * anchor positions below (`warm14DispersedAnchors`).
 */
export const warm14Dispersed: ColorRange = [
    [249, 237, 59], // #F9ED3B
    [247, 227, 58], // #F7E33A
    [245, 216, 56], // #F5D838
    [241, 195, 53], // #F1C335
    [238, 185, 52], // #EEB934
    [236, 174, 50], // #ECAE32
    [235, 158, 47], // #EB9E2F
    [234, 141, 44], // #EA8D2C
    [232, 129, 41], // #EC8129
    [241, 104, 35], // #F16823
    [216, 86, 39], // #D85627
    [191, 68, 44], // #BF442C
    [152, 54, 35], // #983623
    [112, 40, 26] // #70281A
];

/** Colorbar anchor positions for the warm14Dispersed MSAT palette, in percentages from 0 to 100% */
export const warm14DispersedAnchors = [0, 6, 12, 18, 25, 32, 38, 50, 56, 75, 82, 88, 94, 100];

/** 2 stop linear color palette used for MAIR dispersed sources. Using #D85627 at the top end
 * allows it to correspond to the color values of the MSAT scale, creating more unity throughout the
 * product.
 */
export const warm2Dispersed: ColorRange = [
    [249, 237, 59], // #F9ED3B
    [216, 86, 39] // #D85627
];

export const grey11: ColorRange = [
    [61, 61, 61], // #3d3d3d
    [84, 84, 84], // #545454
    [112, 112, 112], // #707070
    [130, 130, 130], // #8c8c8c
    [142, 142, 142], // #969696
    [158, 158, 158], // #a6a6a6
    [176, 176, 176], // #b0b0b0
    [189, 189, 189], // #bdbdbd
    [199, 199, 199], // #c7c7c7
    [212, 212, 212], // #d4d4d4
    [224, 224, 224] // #e0e0e0
];

/**
 * 6-stop kepler.gl green.
 * Caution using this due to Stroop Effect (green = greenery on maps).
 */
export const keplerGreen: ColorRange = [
    [255, 255, 204], // #ffc
    [217, 240, 163], // #d9f0a3
    [173, 221, 142], // #addd8e
    [120, 198, 121], // #78c679
    [49, 163, 84], // #31a354
    [0, 104, 55] // #006837
];

/**
 * 6-stop brown palette, light to dark.
 * https://coolors.co/ede0d4-e6ccb2-ddb892-b08968-9c6644-7f5539
 */
export const brown: ColorRange = [
    [237, 224, 212], // #ede0d4
    [230, 204, 178], // #e6ccb2
    [221, 184, 146], // #ddb892
    [176, 137, 104], // #b08968
    [156, 102, 68], // #9c6644
    [127, 85, 57] // #7f5539
];

/**
 * 6-stop swamp palette, light to dark.
 * https://coolors.co/e9f5db-cfe1b9-b5c99a-97a97c-87986a-718355
 *
 * Caution using this, we found from user test #1 that folks have a hard time distinguishing
 * colors in this palette.
 */
export const swamp: ColorRange = [
    [233, 245, 219], // #e9f5db
    [207, 225, 185], // #cfe1b9
    [181, 201, 154], // #b5c99a
    [151, 169, 124], // #97a97c
    [135, 152, 106], // #87986a
    [113, 131, 85] // #718355
];

/**
 * 5-stop clay palette, light to dark.
 * https://coolors.co/ffcdb2-ffb4a2-e5989b-b5838d-6d6875
 */
export const clay: ColorRange = [
    [255, 205, 178], // #ffcdb2
    [255, 180, 162], // #ffb4a2
    [229, 152, 155], // #e5989b
    [181, 131, 141], // #b5838d
    [109, 104, 117] // #6d6875
];

/**
 * 5-stop pastel blue palette, light to dark.
 * https://coolors.co/dee2ff-feeafa-efd3d7-cbc0d3-8e9aaf
 *
 * Caution using due to Stroop Effect (blue = cool, not good in the context of global warming)
 */
export const pastelBlue: ColorRange = [
    [222, 226, 255], // #dee2ff
    [254, 234, 250], // #feeafa
    [239, 211, 215], // #efd3d7
    [203, 192, 211], // #cbc0d3
    [142, 154, 175] // #8e9aaf
];

/**
 * 256-stop palette from ncview (ssec palette).
 *
 * Tip: recommend offering this this palette for more advanced/scientific audiences.
 * This palette was used for the 2021 MethaneAIR Harvard poster.
 *
 * TODO: any way to reduce size of this?  First 174 values appear to change very predictably.
 */
export const ssec: ColorRange = [
    [0, 0, 45],
    [0, 1, 46],
    [0, 2, 47],
    [0, 3, 48],
    [0, 5, 49],
    [0, 6, 50],
    [0, 7, 51],
    [0, 9, 52],
    [0, 10, 53],
    [0, 11, 54],
    [0, 13, 55],
    [0, 14, 56],
    [0, 15, 57],
    [0, 17, 58],
    [0, 18, 59],
    [0, 19, 60],
    [0, 21, 62],
    [0, 22, 63],
    [0, 23, 64],
    [0, 25, 65],
    [0, 26, 66],
    [0, 27, 67],
    [0, 29, 68],
    [0, 30, 69],
    [0, 31, 70],
    [0, 33, 71],
    [0, 34, 72],
    [0, 35, 73],
    [0, 37, 74],
    [0, 38, 75],
    [0, 39, 76],
    [0, 40, 77],
    [0, 42, 79],
    [0, 43, 80],
    [0, 44, 81],
    [0, 46, 82],
    [0, 47, 83],
    [0, 48, 84],
    [0, 50, 85],
    [0, 51, 86],
    [0, 52, 87],
    [0, 54, 88],
    [0, 55, 89],
    [0, 56, 90],
    [0, 58, 91],
    [0, 59, 92],
    [0, 60, 93],
    [0, 62, 94],
    [0, 63, 96],
    [0, 64, 97],
    [0, 66, 98],
    [0, 67, 99],
    [0, 68, 100],
    [0, 70, 101],
    [0, 71, 102],
    [0, 72, 103],
    [0, 74, 104],
    [0, 75, 105],
    [0, 76, 106],
    [0, 77, 107],
    [0, 79, 108],
    [0, 80, 109],
    [0, 81, 110],
    [0, 83, 111],
    [0, 84, 113],
    [0, 85, 114],
    [0, 87, 115],
    [0, 88, 116],
    [0, 89, 117],
    [0, 91, 118],
    [0, 92, 119],
    [0, 93, 120],
    [0, 95, 121],
    [0, 96, 122],
    [0, 97, 123],
    [0, 99, 124],
    [0, 100, 125],
    [0, 101, 126],
    [0, 103, 127],
    [0, 104, 128],
    [0, 105, 130],
    [0, 107, 131],
    [0, 108, 132],
    [0, 109, 133],
    [0, 111, 134],
    [0, 112, 135],
    [0, 113, 136],
    [0, 114, 137],
    [0, 116, 138],
    [0, 117, 139],
    [0, 118, 140],
    [0, 120, 141],
    [0, 121, 142],
    [0, 122, 143],
    [0, 124, 144],
    [0, 125, 145],
    [0, 126, 147],
    [0, 128, 148],
    [0, 129, 149],
    [0, 130, 150],
    [0, 132, 151],
    [0, 133, 152],
    [0, 134, 153],
    [0, 136, 154],
    [0, 137, 155],
    [0, 138, 156],
    [0, 140, 157],
    [0, 141, 158],
    [0, 142, 159],
    [0, 144, 160],
    [0, 145, 161],
    [0, 146, 162],
    [0, 148, 164],
    [0, 149, 165],
    [0, 150, 166],
    [0, 151, 167],
    [0, 153, 168],
    [0, 154, 169],
    [0, 155, 170],
    [0, 157, 171],
    [0, 158, 172],
    [0, 159, 173],
    [0, 161, 174],
    [0, 162, 175],
    [0, 163, 176],
    [0, 165, 177],
    [0, 166, 178],
    [0, 167, 180],
    [0, 169, 181],
    [0, 170, 182],
    [0, 171, 183],
    [0, 173, 184],
    [0, 174, 185],
    [0, 175, 186],
    [0, 177, 187],
    [0, 178, 188],
    [0, 179, 189],
    [0, 181, 190],
    [0, 182, 191],
    [0, 183, 192],
    [0, 185, 193],
    [0, 186, 194],
    [0, 187, 195],
    [0, 188, 197],
    [0, 190, 198],
    [0, 191, 199],
    [0, 192, 200],
    [0, 194, 201],
    [0, 195, 202],
    [0, 196, 203],
    [0, 198, 204],
    [0, 199, 205],
    [0, 200, 206],
    [0, 202, 207],
    [0, 203, 208],
    [0, 204, 209],
    [0, 206, 210],
    [0, 207, 211],
    [0, 208, 212],
    [0, 210, 214],
    [0, 211, 215],
    [0, 212, 216],
    [0, 214, 217],
    [0, 215, 218],
    [0, 216, 219],
    [0, 218, 220],
    [0, 219, 221],
    [0, 220, 222],
    [0, 222, 223],
    [0, 223, 224],
    [0, 224, 225],
    [0, 225, 226],
    [0, 227, 227],
    [0, 228, 228],
    [0, 229, 229],
    [8, 230, 222],
    [17, 231, 214],
    [26, 232, 206],
    [34, 233, 198],
    [43, 234, 190],
    [52, 235, 182],
    [61, 236, 174],
    [70, 236, 166],
    [78, 237, 158],
    [87, 238, 150],
    [96, 239, 143],
    [105, 240, 135],
    [114, 241, 127],
    [122, 242, 119],
    [131, 242, 111],
    [140, 243, 103],
    [149, 244, 95],
    [157, 245, 87],
    [166, 246, 79],
    [175, 247, 71],
    [184, 248, 63],
    [193, 248, 55],
    [201, 249, 47],
    [210, 250, 39],
    [219, 251, 32],
    [228, 252, 24],
    [237, 253, 16],
    [245, 254, 8],
    [254, 254, 0],
    [255, 250, 0],
    [255, 245, 0],
    [255, 240, 0],
    [255, 236, 0],
    [255, 231, 0],
    [255, 226, 0],
    [255, 221, 0],
    [255, 217, 0],
    [255, 212, 0],
    [255, 207, 0],
    [255, 202, 0],
    [255, 198, 0],
    [255, 193, 0],
    [255, 188, 0],
    [255, 183, 0],
    [255, 179, 0],
    [255, 174, 0],
    [255, 169, 0],
    [255, 164, 0],
    [255, 160, 0],
    [255, 155, 0],
    [255, 150, 0],
    [255, 145, 0],
    [255, 141, 0],
    [255, 136, 0],
    [255, 131, 0],
    [255, 126, 0],
    [255, 122, 0],
    [253, 117, 0],
    [249, 113, 0],
    [246, 109, 0],
    [242, 105, 0],
    [239, 101, 0],
    [236, 97, 0],
    [232, 93, 0],
    [229, 89, 0],
    [225, 85, 0],
    [222, 81, 0],
    [219, 77, 0],
    [215, 73, 0],
    [212, 69, 0],
    [208, 65, 0],
    [205, 61, 0],
    [202, 57, 0],
    [198, 53, 0],
    [195, 49, 0],
    [191, 45, 0],
    [188, 41, 0],
    [185, 37, 0],
    [181, 33, 0],
    [178, 29, 0],
    [175, 24, 0]
];
