import { Color, colorArrayToRgb, ColorRange, rgbaToRgb, sunset5WithoutVibrantYellow } from '@methanesat/colors';
import { alpha, Theme } from '@methanesat/ui-components';

import { DISPERSED_SOURCE_PALETTE, genericMapBackground, SUMMARIZED_TARGET_PALETTE } from '../consts';
import { AreaEmissionsProduct, AreaEmissionsProducts, Platforms } from '../types';
/**
 * Given a rgba color, it applies the map background to it
 * and returns a rgb color. Given a rgb color, it returns
 * the same rgb color.
 * @example
 * colorWithMapBackground([50, 245, 101, 127])
 * // returns [131, 228, 154]
 */
export const colorWithMapBackground = (color: Color) => {
    if (color.length === 3) {
        return color;
    }

    return rgbaToRgb(color, genericMapBackground);
};

/**
 * Returns the array of colors used for area emissions
 */
export const getColorRangeColors = (
    theme: Theme,
    enabled: boolean,
    product: AreaEmissionsProduct = AreaEmissionsProducts.l4,
    platform: Platforms = Platforms.MSAT
) => {
    let colors: ColorRange = sunset5WithoutVibrantYellow;

    if (product === AreaEmissionsProducts.l4) {
        colors = DISPERSED_SOURCE_PALETTE[platform];
    }

    return colors.map((color) => {
        const rgbColor = colorArrayToRgb(colorWithMapBackground(color));

        // When disabled, the colors become paler, which is why the alpha function is used
        return enabled ? rgbColor : alpha(rgbColor, theme.palette.action.disabledOpacity);
    });
};

/**
 * Returns the array of colors used for target totals
 */
export const getTargetTotalsColors = (theme: Theme, enabled: boolean) => {
    return SUMMARIZED_TARGET_PALETTE.map((color) => {
        const rgbColor = colorArrayToRgb(color);

        // When disabled, the colors become paler, which is why the alpha function is used
        return enabled ? rgbColor : alpha(rgbColor, theme.palette.action.disabledOpacity);
    });
};
