import { FeatureCollection, MultiPolygon, Polygon } from 'geojson';

import { BaseQueryApi, createApi, fetchBaseQuery, FetchBaseQueryArgs } from '@reduxjs/toolkit/query/react';

import { EMISSION_DB_URL, IN_IAP } from '../../environmentVariables';
import { CaptureFeatureProperties, StacFeature, StacSearch, StacSearchResponse } from '../../types';
import { Notifier } from '../../utils/Notifier';
import { buffer } from '@turf/turf';

/**
Testing notes: 

* Any new endpoints MUST be added to makeApiSlice in packages/app/test/unit/index.tsx
Omitting endpoints there will cause tests for the component that uses them to fail 
(generally, they'll fail to run at all).

* Any new api slices must be added to the store in wrappedRender in 
packages/app/test/unit/index.tsx (add to both the middleware chain and the list of reducers).

*/

/** Custom base stac query to add IAP headers, necessary for dev & staging deployments */
const fetchCustomBaseStacQuery = (arg: FetchBaseQueryArgs) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (args: any, api: BaseQueryApi, extraOptions: any) => {
        const defaultBaseQuery = fetchBaseQuery({
            ...arg,
            prepareHeaders: (headers: Headers) => {
                if (IN_IAP) {
                    headers.set('X-Requested-With', 'XMLHttpRequest');
                }
                return headers;
            }
        });
        try {
            const result = await defaultBaseQuery(args, api, extraOptions);
            if (IN_IAP && result.error && result.error.status === 401) {
                new Notifier().notify();
            }
            return result;
        } catch (error) {
            return { error: { status: 400, data: error } };
        }
    };
};

export const stacAPISlice = createApi({
    reducerPath: 'stac',
    baseQuery: fetchCustomBaseStacQuery({
        baseUrl: EMISSION_DB_URL
    }),
    endpoints: (builder) => ({
        getStacSearch: builder.query<StacSearchResponse<Polygon, CaptureFeatureProperties>, StacSearch | null>({
            query: (postBody) => {
                const postBodyString = JSON.stringify(postBody);
                return {
                    url: '/search',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: postBodyString
                };
            }
        }),
        getAllStacItemsForCollection: builder.query<
            FeatureCollection<Polygon | MultiPolygon> | undefined,
            { collectionId: string }
        >({
            query: ({ collectionId }) => {
                return { url: `/collections/${collectionId}/items?limit=1000` };
            },
            transformResponse: (response: FeatureCollection<Polygon | MultiPolygon>) => {
                return buffer(response, 1, { units: 'kilometers' });
            }
        }),
        getStacItem: builder.query<StacFeature, { collectionId: string; itemId: string }>({
            query: ({ collectionId, itemId }) => {
                return {
                    url: `/collections/${collectionId}/items/${itemId}`
                };
            }
        })
    })
});

export const { useGetStacSearchQuery, useGetStacItemQuery, useGetAllStacItemsForCollectionQuery } = stacAPISlice;
