/**
 * This file is for utility functions that create or modify
 * StacSearch objects
 */

import { log } from '@methanesat/log';
import { getCollectionName } from '.';
import { DateRanges } from '../../hooks';
import {
    AreaEmissionsProducts,
    Platforms,
    STACCollection,
    SortDirection,
    StacFilter,
    StacFormattedBbox,
    StacSearch
} from '../../types';
import { getMonthEnd, getMonthStart, getQuarterEnd, getQuarterStart } from '../time';

export const getBasicSearch = (): StacSearch => {
    return structuredClone(BASIC_SEARCH);
};

/**
 * Returns the Stac Filter property for a stac search
 * @param targetId
 * @returns Stac Filter
 */
export const getTargetIDFilter = (targetId: string): StacFilter => {
    const filter: StacFilter = {
        op: '=',
        args: [
            {
                property: 'target_id'
            },
            targetId
        ]
    };
    return filter;
};

export const FILTER_LANG = 'cql2-json';

export const formatBbox = (bbox: number[][]): StacFormattedBbox => {
    const corner1 = bbox[0];
    const corner2 = bbox[2];

    const maxLng = Math.max(corner1[0], corner2[0]);
    const maxLat = Math.max(corner1[1], corner2[1]);
    const minLng = Math.min(corner1[0], corner2[0]);
    const minLat = Math.min(corner1[1], corner2[1]);

    return [minLng, minLat, maxLng, maxLat];
};

/**
 *
 * @param date the date on which to base the search. The returned stac search will request the entire calendar month or quarter that includes the given date.
 * @param search the STAC search object to be modified
 * @param dateRange the length of time - ex. `DateRanges.Month`
 * @returns STAC search object, which can be passed to the `/stac/search` endpoint as the request body
 */
export const getTimeBoundStacSearch = (date: Date, search: StacSearch, dateRange: DateRanges) => {
    const clonedSearch = structuredClone(search);
    if (dateRange === DateRanges.Month) {
        clonedSearch.datetime = getDatetimeOneMonthRangeString(date);
    } else if (dateRange === DateRanges.Quarter) {
        clonedSearch.datetime = getDatetimeQuarterlyRangeString(date);
    } else {
        log.error(`Cannot search using the timeframe ${dateRange}`);
    }
    return clonedSearch;
};

/**
 * Get a 24hr day range
 * @param endDate
 * @returns
 */
export const getSingleDayRangeString = (endDate: Date) => {
    const start = new Date(endDate.getTime());
    // set time to midnight
    start.setUTCHours(0, 0, 0, 0);
    const end = new Date(start.getTime());
    end.setUTCDate(endDate.getUTCDate() + 1);

    const startString = start.toISOString();
    const endString = end.toISOString();
    return `${startString}/${endString}`;
};

export const getMonthEndString = (inputDate: Date) => {
    const monthEnd = getMonthEnd(inputDate);
    return monthEnd.toISOString();
};

/**
 * Get a datetime range string for a single calendar month
 * For STAC searches
 * Returns a range of one month based on the UTC date of @date
 *
 * Example:
 * `getDatetimeOneMonthRangeString(new Date('Feb 14 2023'))`
 * returns string for Feb 1 - Feb 28 2023:
 * `"2023-02-01T00:00:00.000Z/2023-02-28T23:59:59.999Z"`
 */
export const getDatetimeOneMonthRangeString = (date: Date): string => {
    const startDate = getMonthStart(date);
    const startString = startDate.toISOString();

    const endDate = getMonthEnd(date);
    const endString = endDate.toISOString();

    return `${startString}/${endString}`;
};

export const getDatetimeQuarterlyRangeString = (date: Date): string => {
    const startDate = getQuarterStart(date);
    const startString = startDate.toISOString();

    const endDate = getQuarterEnd(date);
    const endString = endDate.toISOString();

    return `${startString}/${endString}`;
};

export const getDateRangeFromPlatform = (platform: Platforms) => {
    return platform === Platforms.MSAT ? DateRanges.Quarter : DateRanges.Month;
};

export const getDateRangeEnd = (date: number, dateRange: DateRanges) => {
    if (dateRange === DateRanges.Quarter) return getQuarterEnd(new Date(date));
    return getMonthEnd(new Date(date));
};

export const getDateRangeStart = (date: number, dateRange: DateRanges) => {
    if (dateRange === DateRanges.Quarter) return getQuarterStart(new Date(date));
    return getMonthStart(new Date(date));
};
/**
 * Default Search parameters
 */
export const BASIC_SEARCH: StacSearch = {
    collections: [STACCollection.MethaneAIR_Level4, STACCollection.MethaneSAT_Level4],
    limit: 100,
    fields: {
        include: ['properties', 'collection', 'links', 'geometry', 'assets'],
        exclude: [
            'properties.proj:epsg',
            'properties.proj:wkt2',
            'properties.proj:transform',
            'properties.proj:bbox',
            'properties.proj:shape'
        ]
    },
    sortby: [
        {
            field: 'properties.end_datetime',
            direction: SortDirection.Decending
        }
    ]
};

/**
 * Modify search params to query around a given point
 * @param lng longitude
 * @param lat latitude
 * @param search other serach parameters
 * @returns
 */
export const modifySearchByPoint = (lng: number, lat: number, search?: StacSearch) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.intersects = {
        type: 'Point',
        coordinates: [lng, lat]
    };
    return search;
};

/**
 * Modify search params to query around a given bounding box
 * @param bbox bounding box to intersect
 * @param search other search parameters
 * @returns
 */
export const modifySearchByBoundingBox = (bbox: number[][], search?: StacSearch) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.bbox = formatBbox(bbox);
    return search;
};

/**
 * Modify the item the search will perform for
 * @param itemId the item id to search for
 */
export const modifySearchItem = (itemId: string, search?: StacSearch) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.ids = [itemId];
    return search;
};

/**
 * Modify the collection the search will perform for
 * @param product Data processing level (L3 is concentrations, L4 is emissions).
 * @param platform if you want Sat or Air data (will not provide both as basic search will do this anyway for l4)
 */
export const modifySearchCollection = (
    product: AreaEmissionsProducts = AreaEmissionsProducts.l4,
    platform: Platforms = Platforms.MSAT,
    search?: StacSearch
) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.collections = [getCollectionName(product, platform)];
    return search;
};
