import { ampli } from '../ampli';
import { AMPLITUDE_ENVIRONMENT, ANALYTICS_LOGS_TO_CONSOLE, ANALYTICS_SERVER_URL } from '../environmentVariables';
import { envToReasonablySizedString } from '../environmentVariables/utils';
import { EnrichmentPlugin, Event } from '@amplitude/analytics-types';

export const analytics = ampli;

// This endpoint is used when logging to the console
const NEXT_DEV_ENDPOINT = '/api/dev-analytics';

const GA4_MEASUREMENT_ID = envToReasonablySizedString(process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID);

/**
 * Returns a custom `gtag` function that can be used to push arguments to the Google Analytics `dataLayer` array,
 * potentially before the gtag API loads.
 */
const getGtag = () => {
    window.dataLayer = window.dataLayer || [];
    return function gtag(..._args: unknown[]) {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer?.push(arguments);
    };
};

/**
 *  Retrieves the Google Analytics client ID. Returns a promise that resolves with client ID if it is
 * succesfully retrieved, or undefined if not.
 */
const getGoogleClientID = (): Promise<string | undefined> => {
    const gtag = getGtag();
    return new Promise((resolve) => {
        gtag('get', `${GA4_MEASUREMENT_ID}`, 'client_id', (clientId: string | undefined) => {
            resolve(clientId);
        });
    });
};

/**
 * Enrichment plugin to add `ga_client_id` as a property of all events. This needs to
 * be included in all events forwarded to Google.
 *
 * See https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#plugin-examples
 * for an example on adding plugins
 */
const addGoogleClientIdPlugin = (): EnrichmentPlugin => {
    return {
        execute: async (event: Event) => {
            const googleClientId = await getGoogleClientID();

            googleClientId &&
                (event.event_properties = {
                    ...event.event_properties,
                    googleClientId: googleClientId
                });

            return event;
        }
    };
};

/**
 * Loads amplitude analytics. Depending on the environment variables, it'll either
 * log as a string, send to amplitude, or perform no action.
 */
export const loadAnalytics = async (userId: string) => {
    ampli.load({
        client: {
            configuration: {
                // These are fields that amplitude tracks by default. Currently disabling
                // them so we don't track more information than what is necessary.
                defaultTracking: {
                    attribution: false,
                    fileDownloads: false,
                    formInteractions: false
                },
                // Disables the use of browser cookies and local storage.
                identityStorage: 'none',
                // 4 is debug logs meaning every log that amplitude logs.
                // https://github.com/amplitude/Amplitude-TypeScript/blob/c6355eff3f8e366bee43b3af5f0d2c57d90941d5/packages/analytics-types/src/logger.ts#L10
                logLevel: ANALYTICS_LOGS_TO_CONSOLE ? 4 : undefined,
                // The amplitude sdk must hit an endpoint so when we only want to log to the console,
                // we use a dummy endpoint.
                serverUrl: ANALYTICS_LOGS_TO_CONSOLE ? NEXT_DEV_ENDPOINT : ANALYTICS_SERVER_URL,
                // Disables location based tracking
                trackingOptions: {
                    ipAddress: false
                },
                // For logging to the console, we are using beacon so that we don't have
                // to worry about the respone of the server. The server is a mocked
                // endpoint so the response doesn't matter.
                transport: ANALYTICS_LOGS_TO_CONSOLE ? 'beacon' : undefined
            }
        },
        // If we don't want to use analytics, we can disable it with this flag.
        disabled: !AMPLITUDE_ENVIRONMENT && !ANALYTICS_LOGS_TO_CONSOLE,
        // If we are logging locally, amplitude still wants an environment.
        environment: AMPLITUDE_ENVIRONMENT ?? 'development'
    });
    ampli.client.add(addGoogleClientIdPlugin());

    // Attempt to use the Google Analytics client ID as the Amplitude user ID once gtag loads,
    // and fall back on the UUID if gtag fails to load
    const clientId = await getGoogleClientID();
    ampli.identify(clientId ?? userId);
};
