import { SvgIcon, SvgIconProps } from '@mui/material';

interface PlumeSVGProps {
    colors: string[];
    highlighted?: boolean;
}

/**
 * The SVG element of the Thin Crust SVG Icon. This is used on the
 * map to represent plumes. By making this component, it allows use to
 * reuse the same SVG for the material icon and on the map
 */
export const ThinCrustSVG = ({ colors, highlighted }: PlumeSVGProps) => {
    return (
        // Moved the viewBox to be at 10 10 so that it was easier to add padding on all sides
        // of the outer circle
        // https://stackoverflow.com/a/25072543
        <svg data-testid="thin-crust-plume-svg" viewBox="10 10 28 28" xmlns="http://www.w3.org/2000/svg">
            {highlighted && <circle cx="24" cy="24" r="14" fill={'black'} />}
            <circle cx="24" cy="24" r="12" fill={colors[0]} />
            <circle cx="24" cy="24" r="11" fill={colors[3]} />
            <circle cx="24" cy="24" r="10" fill={colors[5]} />
            <circle cx="24" cy="24" r="9" fill={colors[8]} />
            <circle cx="24" cy="24" r="8" fill={colors[11]} />
        </svg>
    );
};

/**
 * The icon that is displayed within HTML elements like the mini legend
 */
const PlumeIcon = ({ colors, sx, ...otherProps }: SvgIconProps & PlumeSVGProps) => {
    return (
        <SvgIcon sx={[...(Array.isArray(sx) ? sx : [sx])]} {...otherProps}>
            <ThinCrustSVG colors={colors} />
        </SvgIcon>
    );
};

export default PlumeIcon;
