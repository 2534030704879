import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { analyticsMiddleware } from './middleware';
import { emissionsMapReducer } from './reducers';
import { stacAPISlice } from './reducers/api';
import userReducer from './reducers/user';
import tooltipReducer from './reducers/tooltip';

/**
 * The single store for the app, only changed when actions are dispatched.  Not all app state
 * is stored here.  In general, keep things simple until they need to get complex.  Data is a good
 * candidate to be stored inside the store only if it will be used by more than one component,
 * and those components may not always have a parent-descendant relationship.
 * See https://redux.js.org/api/store/
 */
export const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().prepend(analyticsMiddleware.middleware).concat(stacAPISlice.middleware),
    reducer: combineReducers({
        user: userReducer,
        pages: combineReducers({
            emissions: emissionsMapReducer
        }),
        tooltip: tooltipReducer,
        [stacAPISlice.reducerPath]: stacAPISlice.reducer
    })
});

export default store;

/**
 * `RootState` types inferred from the store itself.
 * See https://redux-toolkit.js.org/tutorials/typescript
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * `AppDispatch` types inferred from the store itself.
 * See https://redux-toolkit.js.org/tutorials/typescript
 */
export type AppDispatch = typeof store.dispatch;
