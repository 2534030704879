import dayjs, { ConfigType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNaN } from 'lodash';

import { Milliseconds } from '../types';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const MILLISECONDS_IN_A_SECOND: Milliseconds = 1_000;
export const MILLISECONDS_IN_A_MINUTE: Milliseconds = 60_000;

/**
 * Displays the duration between two times.  Useful for determining the total time during which
 * the data was collected (MAIR is on the order of 2+ hours, MSAT is on the order of ~30 seconds).
 * @example
 * duration('2022-01-01T20:00:00Z', '2022-01-01T20:00:30Z');
 * // -> '30 seconds'
 */
export const duration = (timeStart: ConfigType, timeEnd: ConfigType) => {
    const diffMilliseconds: Milliseconds = dayjs(timeEnd).diff(timeStart);
    const isLessThanAMinute = diffMilliseconds < MILLISECONDS_IN_A_MINUTE;
    if (isLessThanAMinute) {
        /**
         * Special handling needed here since dayjs will output "a few seconds" for everything
         * less than 45 seconds, which is too coarse.
         * See https://day.js.org/docs/en/display/from-now
         */
        return `${millisecondsToSeconds(diffMilliseconds)} seconds`;
    } else {
        return dayjs(timeEnd).from(dayjs(timeStart), true);
    }
};

/**
 * Formats measurement time for display.  UTC time (Zulu time) is preferable to reduce general
 * confusion.  When optional timeEnd is given, the time is formatted as a range.
 * @example
 * formatMeasurementTime('2022-11-01T20:03:20Z');
 * // -> 'Nov 1, 2022 8pm UTC'
 * formatMeasurementTime('2022-11-01T20:03:20Z', '2022-11-01T22:03:20Z');
 * // -> 'Nov 1, 2022 8-10pm UTC'
 */
export const formatMeasurementTime = (time: ConfigType, timeEnd?: ConfigType) => {
    const hour1 = dayjs.utc(time).format('h');
    const hour2 = dayjs.utc(timeEnd).format('h');

    /** Handling for AM->PM, PM->AM. */
    const timePeriod1 = dayjs.utc(time).format('a');
    const timePeriod2 = dayjs.utc(timeEnd).format('a');
    const crossesNoonOrMidnight = timePeriod1 !== timePeriod2;

    const showRange = typeof timeEnd !== 'undefined' && hour1 !== hour2;
    const start = crossesNoonOrMidnight ? `${hour1}${timePeriod1}` : hour1;
    const end = `${hour2}${timePeriod2}`;

    return showRange
        ? dayjs.utc(time).format(`MMM D, YYYY [${start}]-[${end}] UTC`)
        : dayjs.utc(time).format('MMM D, YYYY ha UTC');
};

/**
 * Returns the input date with UTC hours 23:59:59.999
 */
export const getDayEnd = (inputDate: Date) => {
    const copiedDate = new Date(inputDate.getTime());
    copiedDate.setUTCHours(23, 59, 59, 999);
    return copiedDate;
};

export const getIsValidDateString = (input: unknown) => {
    if (typeof input !== 'string') return false;
    const testDate = new Date(input);
    return !isNaN(testDate.getTime());
};

/** tests whether or not the input can produce a valid object */
export const getMakesValidDate = (input: unknown) => {
    if (
        !input ||
        Array.isArray(input) ||
        (typeof input !== 'string' && typeof input !== 'number' && typeof input !== 'object')
    )
        return false;
    let testDate;
    try {
        testDate = new Date(input as string | number | Date);
    } catch {
        return false;
    }
    return !isNaN(testDate.getTime());
};

/** tests whether the input is */
export const isDate = (input: unknown): input is Date => {
    if (!input || typeof input !== 'object' || Array.isArray(input)) return false;
    let _testDate;
    try {
        _testDate = new Date(input as Date);
    } catch {
        return false;
    }
    return true;
};

/**
 *  Determines the localized short month and day of a given date
 */

export const getMonthDay = (inputDate: Date) => {
    const date = new Date(inputDate);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    return `${month} ${day}`;
};

/**
 * Determines the end of the month for any given date.
 */
export const getMonthEnd = (inputDate: Date) => {
    const result = new Date(inputDate.getTime());
    const endYear = inputDate.getUTCFullYear();
    const endMonth = inputDate.getUTCMonth();
    // set to first day of the next month; defaults to midnight
    result.setUTCFullYear(endYear, endMonth + 1, 1);
    // set back one hour, to get to last day of current month
    result.setUTCHours(0, 0, 0, -1);
    result.setUTCHours(23, 59, 59, 999);
    return result;
};

/**
 * Determines the start of the month for any given date.
 */
export const getMonthStart = (inputDate: Date) => {
    const year = inputDate.getUTCFullYear();
    const month = inputDate.getUTCMonth();
    const result = new Date(year, month, 1);
    result.setUTCHours(0, 0, 0, 0);
    return result;
};

/**
 * Determines the end of the quarter for any given date.
 */
export const getQuarter = (inputDate: Date) => {
    const month = inputDate.getUTCMonth();
    const quarter = Math.floor(month / 3);
    const start = quarter * 3;
    return {
        start,
        end: start + 2
    };
};

/**
 * Get the datetime of the end of the quarter for any given date.
 */
export const getQuarterEnd = (inputDate: Date) => {
    // create a new date object from the inputDate
    const result = new Date(inputDate.getTime());
    // get the month that ends the quarter inputDate is in
    const { end: quarterEndMonth } = getQuarter(inputDate);
    //
    // set the day to 1
    //
    // note:
    // If we don't do this, passing May 31 to this function (for example)
    // would give an incorrect result.
    // inputDate = May 31, 2023
    // quarterEndMonth = 5 (June).
    // Set the month to June = June 31 = July 1
    // result = July 31, 2023
    //
    result.setUTCDate(1);
    result.setUTCMonth(quarterEndMonth);
    return getMonthEnd(result);
};

/**
 * Get the datetime of the start of the quarter for any given date.
 */
export const getQuarterStart = (inputDate: Date) => {
    // create a new date object from the inputDate
    const result = new Date(inputDate.getTime());
    // get the month that starts the quarter inputDate is in
    const { start: quarterStartMonth } = getQuarter(inputDate);
    // Set the day to 1, so we don't run into non-existant dates (ex June 30)
    // when we change the month on the following line.
    // See further explaination in `getQuarterEnd`
    result.setUTCDate(1);
    result.setUTCMonth(quarterStartMonth);
    return getMonthStart(result);
};

/**
 * Converts milliseconds to seconds.
 * @example
 * millisecondsToSeconds(1234);
 * // -> 1
 */
export const millisecondsToSeconds = (milliseconds: Milliseconds) =>
    Math.round(milliseconds / MILLISECONDS_IN_A_SECOND);

/**
 * Displays the relative amount of time that's passed.  Useful for giving users a sense of the
 * freshness of the data.
 * @example
 * timeFromNow('2022-01-01T20:03:20Z');
 * // -> 'a year ago'
 */
export const timeFromNow = (timeInPast: ConfigType, referenceTime: ConfigType = Date.now()) =>
    dayjs.utc(referenceTime).to(dayjs.utc(timeInPast));

/**
 * Determines if times are equivalent.
 * @example
 * isSameTime(1667333000000, '2022-11-01T20:03:20Z');
 * // -> true
 */
export const isSameTime = (time1: ConfigType, time2: ConfigType) => {
    return dayjs.utc(time1).isSame(dayjs.utc(time2));
};
